import { Modal } from 'antd';
import React, { useState } from 'react';
import { useParams } from "react-router";
var QRCode = require('qrcode.react');
export default function OpenPage() {
    let { id } = useParams();
    const [visible, setVisible] = useState(true)

    const handleCancel = e => {
        setVisible(false)
    };
    return (
        <Modal
            title="Token"
            visible={visible}
            onCancel={handleCancel}
            footer={null}
        >
            <div style={{ textAlign: 'center' }}>
                <QRCode value={"" + id} includeMargin={true} size={250} />
                <h1>{id}</h1>
            </div>
        </Modal>
    );
}