import { Modal } from 'antd';
import React from 'react';
var QRCode = require('qrcode.react');

class QRModal extends React.Component {

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    render() {
        return (

            <>
                {/* <Modal
                    title="Please provide this token to the security person when requested"
                    visible={this.props.visible}
                    onOk={this.props.handleOk}
                    onCancel={this.props.handleOk}
                    // maskClosable={false}
                    footer={null}
                    closable={false}
                >
                    <div style={{ textAlign: 'center' }}>
                        <QRCode value={"" + this.props.qrCode} includeMargin={true} size={250} />
                        <h1>{this.props.qrCode}</h1>
                    </div>
                    <span>* You will also receive this token as a SMS with a link to view the QR Code </span>
                </Modal> */}
                <Modal
                    title=""
                    visible={this.props.visible}
                    onOk={this.props.handleOk}
                    onCancel={this.props.handleOk}
                    // maskClosable={false}
                    footer={null}
                    closable={false}
                >
                    <div style={{ textAlign: 'center' }}>
                        {/* <QRCode value={"" + this.props.qrCode} includeMargin={true} size={250} /> */}
                        <h1>Please wait. <br />Your request is being sent to our team.</h1>
                    </div>
                    {/* <span>* You will also receive this token as a SMS with a link to view the QR Code </span> */}
                </Modal>
            </>
        );
    }
}
export default QRModal;