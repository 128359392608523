import {
    PlusCircleOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import React, { useState } from 'react';
import apiCollection from '../resources/apiCollection';
import { postApi } from '../resources/ApiContent';
import QRModal from './QRModal';
import TextArea from 'antd/lib/input/TextArea';

const HomePageForm = (state) => {
    const [visible, setVisible] = useState(false)
    const [qrCode, setQrCode] = useState('')
    const [nameObjArr, setNameObjArr] = useState([])
    const [count, setCount] = useState(0)

    const [form] = Form.useForm();

    const onFinish = async values => {

        try {
            values.city = "";
            const result = await postApi(apiCollection.createVisitor, values);
            if (result.data.success) {
                message.success(result.data.message);
                setVisible(true)
                // setQrCode(result.data.qrCode)
                onReset();
            } else {
                message.error(result.data.message);
            }

        } catch (errors) {
            console.log(errors)

        }

    };


    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    const onReset = () => {
        form.resetFields();

    };

    const closeModal = () => {
        setVisible(false)
    };

    const onAddName = () => {
        var c = count + 1
        var nameObjArr = []
        nameObjArr.push({
            id: c
        })
        setNameObjArr(nameObjArr)
        setCount(c)
    };

    return (

        < Row gutter={{ xs: 8, sm: 16, md: 24 }} style={{ marginTop: 30 }}>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 12, offset: 6 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
                xl={{ span: 12, offset: 6 }}
            >
                <Card bordered={true} hoverable={true} className="visitorForm"
                    title="Please Fill the Form and Register"
                >
                    <Form
                        name="userForm"
                        initialValues={{ count: 0, visible: false }}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                    >

                        <Row gutter={24}>
                            <Col span={22}>
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your name!' }]}
                                >
                                    <Input placeholder="Please Enter your Name" style={{ width: "80%" }} />
                                </Form.Item >
                            </Col>
                            <Col span={2}>
                                <PlusCircleOutlined style={{ fontSize: 25, marginLeft: -30 }} onClick={onAddName} />
                            </Col>
                        </Row>

                        {nameObjArr.map((row, i) => {
                            return (
                                <Row gutter={24}>
                                    <Col span={22}>
                                        <Form.Item
                                            name={"name" + i}
                                            rules={[{ required: true, message: 'Please input your name!' }]}
                                        >
                                            <Input placeholder="Please Enter your Name" style={{ width: "80%" }} />
                                        </Form.Item >
                                    </Col>
                                </Row>
                            )
                        })

                        }
                        <Row gutter={24}>
                            <Col span={22} >
                                <Form.Item
                                    name="phone"
                                    rules={[{ required: true, pattern: new RegExp("^(\\+\\d{1,2})?\\(?\\d{3}\\)?\\d{3}\\d{4}$"), message: "Invalid Contact Number" }]}
                                >
                                    <Input placeholder="Please Enter your Phone Number" style={{ width: "80%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={22}>
                                <Form.Item
                                    name="nic"
                                // rules={[{ required: true, pattern: new RegExp("^([0-9]{9}[x|X|v|V]|[0-9]{12})$"), message: "Invalid Nic Number" }]}
                                >
                                    <Input placeholder="Please Enter your NIC" style={{ width: "80%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Row gutter={24}>
                            <Col span={22}>
                                <Form.Item
                                    name="city"
                                    // rules={[{ required: true, message: 'Please input your city!' }]}
                                >
                                    <Input placeholder="Please Enter your City" style={{ width: "80%" }} />
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <Row gutter={24}>
                            <Col span={22}>
                                <Form.Item
                                    name="reason"
                                >
                                    <TextArea placeholder="Reason" style={{ width: "80%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <span style={{ color: "#9c6666" }}>Disclaimer: By providing your information, you consent to its storage for security and compliance reasons. This data will be used to maintain a record of visitors for the safety of our workplace and in accordance with ISO regulations. We respect your privacy and will not share your information with third parties.</span>

                        <Form.Item >
                            <Button htmlType="button" onClick={onReset} style={{ float: "left", borderRadius: 30 }} >
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ float: "right", borderRadius: 30 }} >
                                Submit
                            </Button>

                        </Form.Item>
                    </Form >
                </Card >
            </Col >
            <QRModal
                visible={visible}
                qrCode={qrCode}
                handleOk={closeModal} />
        </Row >
    )
}
export default HomePageForm;